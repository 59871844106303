import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsCyberArk: ArrayStepsConfig = {
  providerLabel: 'Cyberark SCIM',
  type: 'Array',
  steps: [
    {
      name: 'Select or create your CyberArk application',
      // eslint-disable-next-line react/display-name
      render: ({ directory, onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            CyberArk supports SCIM provisioning in the context of a SAML app.
            The usual set up is to set up your SAML app first. Log in to the
            CyberArk Identity Admin Portal and select "Web Apps" from the
            left-side navigation. Then, select or create your SAML app.
          </Text>

          <Text>
            In the SAML app, open the "Provisioning" tab, and select the box to
            "Enable provisioning for this application".
          </Text>

          <Img
            priority
            height={1000}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-1.png"
            width={2400}
          />

          <Text>Click "Yes" in the confirmation modal.</Text>

          <Img
            priority
            height={900}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-2.png"
            width={2200}
          />

          <Text>
            Copy and paste the endpoint into the "SCIM Service URL" field.
          </Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Text>
            Copy and paste the Bearer Token into the "Bearer Token" field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearer_token}
          />

          <Img
            priority
            height={900}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-3-ap.png"
            width={1900}
          />

          <Confirm
            label="I've entered and saved the Provisioning settings."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign users to app in CyberArk',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Users assigned to the SAML app will be synced. You can assign users
            by going to the "Permissions" tab, and selecting "Add".
          </Text>

          <Img
            priority
            height={601}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-users-1-ap.png"
            width={961}
          />

          <Text>
            Search for the individual users and/or groups of users that you
            would like to assign to the app, and check the box next to them.
            Click "Add" when you are finished.
          </Text>

          <Img
            priority
            height={599}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-users-2.png"
            width={762}
          />

          <Confirm
            label="I've completed user assignment."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure the Role Mappings in CyberArk',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Roles mapped in the application will be synced as groups. The roles
            are mapped on the Provisioning settings page, by selecting the "Add"
            button.
          </Text>

          <Img
            priority
            height={900}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-4.png"
            width={1700}
          />

          <Text>
            In the role mapping modal, select the role you'd like to map, and
            then create a destination group. The name will be what you see as
            the group name in directory sync. All users assigned to that role
            will be members of the mapped group. Select "Done".
          </Text>

          <Img
            priority
            height={900}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-5.png"
            width={1700}
          />

          <Text>
            After the role mapping is completed, click "Save". The SCIM
            configuration part of the setup is complete.
          </Text>

          <Confirm
            label="I've completed the role mappings."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Initiate the directory sync run in CyberArk',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            In CyberArk, navigate to the Settings {'>'} Users {'>'} Outbound
            Provisioning page. Under Synchronizations, start the sync. You can
            also set up scheduled syncs here.
          </Text>

          <Img
            priority
            height={900}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-6.png"
            width={2000}
          />

          <Confirm
            label="I've initiated the SCIM sync run."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <>
          <Text>
            In addition to configuring attribute mappings in CyberArk, you also
            need to map these additional attributes the application is
            expecting.
          </Text>

          <Text>
            To see the names of attributes that you can map from your Directory
            Provider, return to the "Provisioning" tab in your CyberArk SCIM
            application and expand the "Provisioning Script" section. Here
            CyberArk maps the attributes from their system to the standard
            attributes from the{' '}
            <Link
              newTab
              appearance="highlight"
              href={'https://datatracker.ietf.org/doc/html/rfc7643#section-4.1'}
            >
              SCIM RFC.{' '}
            </Link>
          </Text>

          <Img
            priority
            height={831}
            src="/directory-sync/cyberark-scim/v1/cyberark-scim-custom-attributes.png"
            width={1523}
          />

          <Text>
            If you would like to add any additional attributes here, please
            follow{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://docs.cyberark.com/Product-Doc/OnlineHelp/Idaptive/Latest/en/Content/Applications/AppsOvw/SCIM-Provisioning.htm'
              }
            >
              CyberArk's documentation{' '}
            </Link>
            on this process.
          </Text>

          <MapAttributesStep {...directoryStepProps} />
        </>
      ),
    },
  ],
};
